import React from "react";
import Style from "./projects.module.scss";
import Button from "../../components/button/Button.js";

const ProjectCard = ({
  ProjectImg,
  ProjectTitle,
  ProjectText,
  ProjectLink,
}) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  return (
    <div className={Style.projectCard}>
      <img className="w-100" src={BASE_URL + ProjectImg} alt="" />
      <h2>{ProjectTitle}</h2>
      <p>{ProjectText}</p>
      <Button linkTo={ProjectLink} isWhite={true} />
    </div>
  );
};

export default ProjectCard;
