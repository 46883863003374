import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Layout from "../../layout/Layout.js";
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs.js";
import { useLocation } from "react-router-dom";

const Contact = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Fragment>
      <MetaTags>
        <title>Armedem | İletişim</title>
        <meta name="description" content="Ardenem | Anasayfa" />
      </MetaTags>
      <Layout>
        <div className="section-bg behind-nav"></div>
        <Breadcrumbs
          breadcrumbsitem="İletişim"
          breadcrumbsitemLink="iletisim"
        />
        <section className="section-bg">
          <div className="container py-5">
            <div className="row">
              <div className="col-md-4 pb-5">
                <h1 className="text-white iletisimh1">İletişim</h1>
                <div className="d-flex flex-column justify-content-center h-100">
                  <p className="text-white">
                    ADRES: <br /> Karadolap Mahallesi <br />
                    Gaziosmanpaşa Caddesi <br /> No: 120 / E <br /> EYÜP -
                    İSTANBUL{" "}
                  </p>{" "}
                  <p className="text-white">
                    TELEFON: <br />{" "}
                    <a
                      className="text-white text-decoration-none"
                      href="tel:+905322571583"
                    >
                      532 257 15 83
                    </a>{" "}
                    -{" "}
                    <a
                      className="text-white text-decoration-none"
                      href="+905392810050"
                    >
                      0539 281 00 50
                    </a>
                  </p>
                  <p className="text-white">
                    E-POSTA: <br />{" "}
                    <a
                      className="text-white text-decoration-none"
                      href="mailto:armedem37@gmail.com"
                    >
                      armedem37@gmail.com
                    </a>{" "}
                    -{" "}
                    <a
                      className="text-white text-decoration-none"
                      href="mailto:info@armedeminsaat.com.tr"
                    >
                      info@armedeminsaat.com.tr
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-md-8 pb-5">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3008.107893144109!2d28.906027415415775!3d41.066634179294866!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab1fdb33e4619%3A0xbc1064de11c20d6b!2sArmedem%20in%C5%9Faat!5e0!3m2!1str!2str!4v1616750017977!5m2!1str!2str"
                  width="100%"
                  height="450"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </Fragment>
  );
};

export default Contact;
