import React, { Fragment, useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Layout from "../../layout/Layout.js";
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs";
import Style from "./projects.module.scss";
import ProjectCard from "./ProjectCard.js";
import { useLocation } from "react-router-dom";

const Projects = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [projectCard] = useState([
    {
      img: "/images/sair-sirzat/sair-sirzat-kapak.jpg",
      title: "Şair Şirzat Projesi",
      text:
        "Projemiz toplam 8 daireden oluşmaktadır. Proje kapsamında bahçe katı ve giriş katı tek daire şeklinde tasarlanmış ve brüt 92’şer metrekaredir. 1. ve 2. katlarda ise 2’şer daire bulunmaktadır. Bu daireler ise brüt 70’şer metrekaredir. Projemizin 3. katında yer alan dairelerimiz ise dubleks daire olarak tasarlanmıştır.",
      url: "/projelerimiz/sair-sirtaz-apt",
    },
  ]);

  return (
    <Fragment>
      <MetaTags>
        <title>Armedem | Projelerimiz</title>
        <meta name="description" content="Ardenem | Anasayfa" />
      </MetaTags>
      <Layout>
        {/* <div className="section-bg behind-nav"></div> */}
        <div className="projectsHero"></div>
        <div className="container-fluid p-0 hero_banner_wrapper z__1">
          <div className="hero_banner"></div>
        </div>
        <div className="section-bg-none">
          <Breadcrumbs
            breadcrumbsitem="Projelerimiz"
            breadcrumbsitemLink="projelerimiz"
          />
        </div>
        <section className="section-bg section-bg-gradient">
          <div className="container py-4">
            <div className={Style.projectsWrapper + " row"}>
              <div className="col-12">
                <h1 className="text-white pb-3">Projelerimiz</h1>
              </div>
              {projectCard.map((project, key) => (
                <div key={key} className="col-md-4">
                  <ProjectCard
                    ProjectImg={project.img}
                    ProjectTitle={project.title}
                    ProjectText={project.text}
                    ProjectLink={project.url}
                  />
                </div>
              ))}
            </div>
          </div>
        </section>
        <section className="section-bg">
          <div className="container py-5">
            <div className="row">
              <div className="col-md-8 pb-3">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3008.107893144109!2d28.906027415415775!3d41.066634179294866!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab1fdb33e4619%3A0xbc1064de11c20d6b!2sArmedem%20in%C5%9Faat!5e0!3m2!1str!2str!4v1616750017977!5m2!1str!2str"
                  width="100%"
                  height="450"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </div>

              <div className="col-md-4 d-flex flex-column justify-content-center pb-3">
                <h1 className="text-white">SATIŞ OFİSİ</h1>
                <p className="text-white">
                  ADRES: <br /> Karadolap Mahallesi <br />
                  Gaziosmanpaia Caddesi <br /> No: 120 / E <br /> EYÜPSULTAN -
                  İSTANBUL{" "}
                </p>
                <p className="text-white">
                  TELEFON: <br />{" "}
                  <a
                    className="text-white text-decoration-none"
                    href="tel:+905322571583"
                  >
                    532 257 15 83
                  </a>{" "}
                  -{" "}
                  <a
                    className="text-white text-decoration-none"
                    href="+905392810050"
                  >
                    0539 281 00 50
                  </a>
                </p>
                <p className="text-white">
                  E-POSTA: <br />{" "}
                  <a
                    className="text-white text-decoration-none"
                    href="mailto:armedem37@gmail.com"
                  >
                    armedem37@gmail.com
                  </a>{" "}
                  -{" "}
                  <a
                    className="text-white text-decoration-none"
                    href="mailto:info@armedeminsaat.com.tr"
                  >
                    info@armedeminsaat.com.tr
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </Fragment>
  );
};

export default Projects;
