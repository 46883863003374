import React, { Fragment, useEffect, useState } from "react";
import Flickity from "react-flickity-component";
import MetaTags from "react-meta-tags";
import Layout from "../../layout/Layout";
import Style from "./projectDetails.module.scss";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs.js";
import { DaireButton } from "../../components/button/Button";

const ProjectDetails = () => {
  const flickityOptions = {
    initialIndex: 0,
    contain: true,
    adaptiveHeight: true,
  };
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [photoIndex1, setPhotoIndex1] = useState(0);
  const [isOpen1, setIsOpen1] = useState(false);
  const [photoIndex2, setPhotoIndex2] = useState(0);
  const [isOpen2, setIsOpen2] = useState(false);
  const [photoIndex3, setPhotoIndex3] = useState(0);
  const [isOpen3, setIsOpen3] = useState(false);
  const [photoIndex4, setPhotoIndex4] = useState(0);
  const [isOpen4, setIsOpen4] = useState(false);
  const [photoIndex5, setPhotoIndex5] = useState(0);
  const [isOpen5, setIsOpen5] = useState(false);
  const [photoIndex6, setPhotoIndex6] = useState(0);
  const [isOpen6, setIsOpen6] = useState(false);
  const [photoIndex7, setPhotoIndex7] = useState(0);
  const [isOpen7, setIsOpen7] = useState(false);
  const [photoIndex8, setPhotoIndex8] = useState(0);
  const [isOpen8, setIsOpen8] = useState(false);
  const [photoIndex9, setPhotoIndex9] = useState(0);
  const [isOpen9, setIsOpen9] = useState(false);

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [disGorsel] = useState([
    "/images/sair-sirzat/dis-gorseller/bina-gorsel-1.jpg",
    "/images/sair-sirzat/dis-gorseller/bina-gorsel-2.jpg",
    "/images/sair-sirzat/dis-gorseller/bina-gorsel-3.jpg",
    "/images/sair-sirzat/dis-gorseller/bina-gorsel-4.jpg",
    "/images/sair-sirzat/dis-gorseller/bina-gorsel-5.jpg",
    "/images/sair-sirzat/dis-gorseller/bina-gorsel-6.jpg",
    "/images/sair-sirzat/dis-gorseller/bina-gorsel-7.jpg",
  ]);
  const [disGorselThumb] = useState([
    "/images/sair-sirzat/dis-gorseller/thumb/bina-gorsel-1.jpg",
    "/images/sair-sirzat/dis-gorseller/thumb/bina-gorsel-2.jpg",
    "/images/sair-sirzat/dis-gorseller/thumb/bina-gorsel-3.jpg",
    "/images/sair-sirzat/dis-gorseller/thumb/bina-gorsel-4.jpg",
    "/images/sair-sirzat/dis-gorseller/thumb/bina-gorsel-5.jpg",
    "/images/sair-sirzat/dis-gorseller/thumb/bina-gorsel-6.jpg",
    "/images/sair-sirzat/dis-gorseller/thumb/bina-gorsel-7.jpg",
  ]);
  const [kat3OnCephe] = useState([
    "/images/sair-sirzat/kat-3-on-cephe-dublex/kat-3-oncephe-dublex-1.jpg",
    "/images/sair-sirzat/kat-3-on-cephe-dublex/kat-3-oncephe-dublex-2.jpg",
    "/images/sair-sirzat/kat-3-on-cephe-dublex/kat-3-oncephe-dublex-3.jpg",
    "/images/sair-sirzat/kat-3-on-cephe-dublex/kat-3-oncephe-dublex-4.jpg",
    "/images/sair-sirzat/kat-3-on-cephe-dublex/kat-3-oncephe-dublex-5.jpg",
    "/images/sair-sirzat/kat-3-on-cephe-dublex/kat-3-oncephe-dublex-6.jpg",
    "/images/sair-sirzat/kat-3-on-cephe-dublex/kat-3-oncephe-dublex-7.jpg",
    "/images/sair-sirzat/kat-3-on-cephe-dublex/kat-3-oncephe-dublex-8.jpg",
    "/images/sair-sirzat/kat-3-on-cephe-dublex/kat-3-oncephe-dublex-9.jpg",
    "/images/sair-sirzat/kat-3-on-cephe-dublex/kat-3-oncephe-dublex-10.jpg",
    "/images/sair-sirzat/kat-3-on-cephe-dublex/kat-3-oncephe-dublex-11.jpg",
    "/images/sair-sirzat/kat-3-on-cephe-dublex/kat-3-oncephe-dublex-12.jpg",
    "/images/sair-sirzat/kat-3-on-cephe-dublex/kat-3-oncephe-dublex-13.jpg",
    "/images/sair-sirzat/kat-3-on-cephe-dublex/kat-3-oncephe-dublex-14.jpg",
    "/images/sair-sirzat/kat-3-on-cephe-dublex/kat-3-oncephe-dublex-15.jpg",
    "/images/sair-sirzat/kat-3-on-cephe-dublex/kat-3-oncephe-dublex-16.jpg",
    "/images/sair-sirzat/kat-3-on-cephe-dublex/kat-3-oncephe-dublex-17.jpg",
  ]);
  const [kat1OnCephe] = useState([
    "/images/sair-sirzat/kat-1-2-on-cephe/kat-1-on-cephe-1.jpg",
    "/images/sair-sirzat/kat-1-2-on-cephe/kat-1-on-cephe-2.jpg",
    "/images/sair-sirzat/kat-1-2-on-cephe/kat-1-on-cephe-3.jpg",
    "/images/sair-sirzat/kat-1-2-on-cephe/kat-1-on-cephe-4.jpg",
    "/images/sair-sirzat/kat-1-2-on-cephe/kat-1-on-cephe-5.jpg",
    "/images/sair-sirzat/kat-1-2-on-cephe/kat-1-on-cephe-6.jpg",
    "/images/sair-sirzat/kat-1-2-on-cephe/kat-1-on-cephe-7.jpg",
    "/images/sair-sirzat/kat-1-2-on-cephe/kat-1-on-cephe-8.jpg",
    "/images/sair-sirzat/kat-1-2-on-cephe/kat-1-on-cephe-9.jpg",
    "/images/sair-sirzat/kat-1-2-on-cephe/kat-1-on-cephe-10.jpg",
    "/images/sair-sirzat/kat-1-2-on-cephe/kat-1-on-cephe-11.jpg",
    "/images/sair-sirzat/kat-1-2-on-cephe/kat-1-on-cephe-12.jpg",
    "/images/sair-sirzat/kat-1-2-on-cephe/kat-1-on-cephe-13.jpg",
  ]);
  const [girisKat] = useState([
    "/images/sair-sirzat/giris-kat/giris-kat-1.jpg",
    "/images/sair-sirzat/giris-kat/giris-kat-2.jpg",
    "/images/sair-sirzat/giris-kat/giris-kat-3.jpg",
    "/images/sair-sirzat/giris-kat/giris-kat-4.jpg",
    "/images/sair-sirzat/giris-kat/giris-kat-5.jpg",
    "/images/sair-sirzat/giris-kat/giris-kat-6.jpg",
    "/images/sair-sirzat/giris-kat/giris-kat-7.jpg",
    "/images/sair-sirzat/giris-kat/giris-kat-8.jpg",
    "/images/sair-sirzat/giris-kat/giris-kat-9.jpg",
    "/images/sair-sirzat/giris-kat/giris-kat-10.jpg",
    "/images/sair-sirzat/giris-kat/giris-kat-11.jpg",
  ]);
  const [bahceKat] = useState([
    "/images/sair-sirzat/bahce-kat/bahce-kat-1.jpg",
    "/images/sair-sirzat/bahce-kat/bahce-kat-2.jpg",
    "/images/sair-sirzat/bahce-kat/bahce-kat-3.jpg",
    "/images/sair-sirzat/bahce-kat/bahce-kat-4.jpg",
    "/images/sair-sirzat/bahce-kat/bahce-kat-5.jpg",
    "/images/sair-sirzat/bahce-kat/bahce-kat-6.jpg",
    "/images/sair-sirzat/bahce-kat/bahce-kat-7.jpg",
    "/images/sair-sirzat/bahce-kat/bahce-kat-8.jpg",
    "/images/sair-sirzat/bahce-kat/bahce-kat-9.jpg",
    "/images/sair-sirzat/bahce-kat/bahce-kat-10.jpg",
    "/images/sair-sirzat/bahce-kat/bahce-kat-11.jpg",
    "/images/sair-sirzat/bahce-kat/bahce-kat-12.jpg",
  ]);
  const [kat3ArkaCephe] = useState([
    "/images/sair-sirzat/kat-3-arka-cephe-dublex/kat-3-arka-cephe-1.jpg",
    "/images/sair-sirzat/kat-3-arka-cephe-dublex/kat-3-arka-cephe-2.jpg",
    "/images/sair-sirzat/kat-3-arka-cephe-dublex/kat-3-arka-cephe-3.jpg",
    "/images/sair-sirzat/kat-3-arka-cephe-dublex/kat-3-arka-cephe-4.jpg",
    "/images/sair-sirzat/kat-3-arka-cephe-dublex/kat-3-arka-cephe-5.jpg",
    "/images/sair-sirzat/kat-3-arka-cephe-dublex/kat-3-arka-cephe-6.jpg",
    "/images/sair-sirzat/kat-3-arka-cephe-dublex/kat-3-arka-cephe-7.jpg",
    "/images/sair-sirzat/kat-3-arka-cephe-dublex/kat-3-arka-cephe-8.jpg",
    "/images/sair-sirzat/kat-3-arka-cephe-dublex/kat-3-arka-cephe-9.jpg",
    "/images/sair-sirzat/kat-3-arka-cephe-dublex/kat-3-arka-cephe-10.jpg",
    "/images/sair-sirzat/kat-3-arka-cephe-dublex/kat-3-arka-cephe-11.jpg",
    "/images/sair-sirzat/kat-3-arka-cephe-dublex/kat-3-arka-cephe-12.jpg",
    "/images/sair-sirzat/kat-3-arka-cephe-dublex/kat-3-arka-cephe-13.jpg",
    "/images/sair-sirzat/kat-3-arka-cephe-dublex/kat-3-arka-cephe-14.jpg",
    "/images/sair-sirzat/kat-3-arka-cephe-dublex/kat-3-arka-cephe-15.jpg",
    "/images/sair-sirzat/kat-3-arka-cephe-dublex/kat-3-arka-cephe-16.jpg",
  ]);
  const [kat1ArkaCephe] = useState([
    "/images/sair-sirzat/kat-1-2-arka-cephe/kat-1-arka-cephe-1.jpg",
    "/images/sair-sirzat/kat-1-2-arka-cephe/kat-1-arka-cephe-2.jpg",
    "/images/sair-sirzat/kat-1-2-arka-cephe/kat-1-arka-cephe-3.jpg",
    "/images/sair-sirzat/kat-1-2-arka-cephe/kat-1-arka-cephe-4.jpg",
    "/images/sair-sirzat/kat-1-2-arka-cephe/kat-1-arka-cephe-5.jpg",
    "/images/sair-sirzat/kat-1-2-arka-cephe/kat-1-arka-cephe-6.jpg",
    "/images/sair-sirzat/kat-1-2-arka-cephe/kat-1-arka-cephe-7.jpg",
    "/images/sair-sirzat/kat-1-2-arka-cephe/kat-1-arka-cephe-8.jpg",
    "/images/sair-sirzat/kat-1-2-arka-cephe/kat-1-arka-cephe-9.jpg",
    "/images/sair-sirzat/kat-1-2-arka-cephe/kat-1-arka-cephe-10.jpg",
    "/images/sair-sirzat/kat-1-2-arka-cephe/kat-1-arka-cephe-11.jpg",
    "/images/sair-sirzat/kat-1-2-arka-cephe/kat-1-arka-cephe-12.jpg",
    "/images/sair-sirzat/kat-1-2-arka-cephe/kat-1-arka-cephe-13.jpg",
  ]);

  return (
    <Fragment>
      <MetaTags>
        <title>Armedem | Şair Şirtaz Apartmanı Eyüp</title>
        <meta
          name="description"
          content="Ardenem | Şair Şirtaz Apartmanı Eyüp"
        />
      </MetaTags>
      <Layout>
        <div className="section-bg behind-nav"></div>
        <Breadcrumbs
          breadcrumbsitem="Projelerimiz"
          breadcrumbsitemLink="../projelerimiz"
          breadcrumbsitem2="Şair Şirtaz Apt"
          breadcrumbsitemLink2="sair-sirtaz-apt"
        />
        <section className={Style.firstSection + " section-bg"}>
          <div className="container">
            <div className="row">
              <div className="col-md-4 pb-3">
                <img
                  className="w-100"
                  src={BASE_URL + "/images/sair-sirzat/sair-sirzat-kapak.jpg"}
                  alt=""
                />
              </div>
              <div className={"col-md-8 pb-3 " + Style.kunye}>
                <h1 className="pb-3">Şair Şirzat Projesi</h1>
                <p>
                  Projemiz toplam 8 daireden oluşmaktadır. Proje kapsamında
                  bahçe katı ve giriş katı tek daire şeklinde tasarlanmış ve
                  brüt 92’şer metrekaredir. 1. ve 2. katlarda ise 2’şer daire
                  bulunmaktadır. Bu daireler ise brüt 70’şer metrekaredir.
                  Projemizin 3. katında yer alan dairelerimiz ise dubleks daire
                  olarak tasarlanmıştır. Ön cepheye bakan dubleks daire brüt 115
                  metrekare iken arka cepheye bakan dairemiz ise brüt 125
                  metrekaredir. Bahçe katı, giriş katı, 1. ve 2. katta yer alan
                  dairelerimiz 2+1 dairelerdir. Ayrıca bahçe ve giriş kat
                  dairelerimizde ebeveyn banyosu da mevcuttur. 3. katta yer alan
                  dubleks dairelerimiz ise 4+1 ve 2 banyoludur.
                </p>
                <p>
                  Projemiz konum olarak ana caddeye ve ulaşım olanaklarına
                  oldukça yakın bir konumda yer almaktadır. Binamız kompozit
                  kaplamalı ve taş yünü ısı yalıtımlıdır. Binamız asansörlüdür.
                  Dairelerimizin içerisinde kombi, petek, ısıcam, Amerikan panel
                  kapılı, görüntülü diafon sistemi yer almaktadır. Daire
                  içerisinde kullanılan laminant parke, kalebodur, fayans ve
                  mutfak dolapları ise birinci sınıf malzeme kullanılarak
                  yapılmıştır. Ayrıca hem binanın içerisinde hem de bina
                  dışarısında güvenlik kamera sistemleri kurulmuştur.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="section-bg">
          <div className={Style.container + " container"}>
            <div className={"row " + Style.row}>
              <h2 className={Style.sectionTitle}>DIŞ GÖRSELLER</h2>
              <Flickity
                className={"carousel dis-gorsel " + Style.DisGorsel} // default ''
                elementType={"div"} // default 'div'
                options={flickityOptions} // takes flickity options {}
                disableImagesLoaded={false} // default false
                reloadOnUpdate // default false
                static // default false
              >
                {disGorselThumb.map((gorsel, key) => (
                  <div className={Style.imgWrapper} key={key}>
                    <img
                      onClick={() => {
                        setIsOpen1(true);
                        setPhotoIndex1(key % disGorselThumb.length);
                      }}
                      className="w-100"
                      src={BASE_URL + gorsel}
                      alt=""
                    />
                    {console.log(photoIndex1)}
                  </div>
                ))}
              </Flickity>
              {isOpen1 && (
                <Lightbox
                  mainSrc={disGorsel[photoIndex1]}
                  nextSrc={disGorsel[(photoIndex1 + 1) % disGorsel.length]}
                  prevSrc={
                    disGorsel[
                      (photoIndex1 + disGorsel.length - 1) % disGorsel.length
                    ]
                  }
                  onCloseRequest={() => setIsOpen1(false)}
                  onMovePrevRequest={() =>
                    setPhotoIndex1(
                      (photoIndex1 + disGorsel.length - 1) % disGorsel.length
                    )
                  }
                  onMoveNextRequest={() =>
                    setPhotoIndex1((photoIndex1 + 1) % disGorsel.length)
                  }
                />
              )}
            </div>
          </div>
        </section>
        <section className="section-bg">
          <div className={Style.container + " container"}>
            <div className={"row " + Style.row}>
              <div className="col-12">
                <h2 className={Style.sectionTitle}>KAT GÖRSELLER</h2>
              </div>
              <div className="col-md-6 offset-md-3 basmali_bina">
                <div className={Style.on_cephe}>ÖN CEPHE</div>
                <img
                  className={Style.bina + " w-100"}
                  src={BASE_URL + "/images/sair-sirzat/bina.png"}
                  alt=""
                />
                <div className={Style.arka_cephe}>ARKA CEPHE</div>
                <DaireButton
                  classWrapper="pro1"
                  oncephe={true}
                  katismi="3. Kat Dublex"
                  left={true}
                  onClick={() => {
                    setIsOpen2(true);
                  }}
                />
                {isOpen2 && (
                  <Lightbox
                    mainSrc={kat3OnCephe[photoIndex2]}
                    nextSrc={
                      kat3OnCephe[(photoIndex2 + 1) % kat3OnCephe.length]
                    }
                    prevSrc={
                      kat3OnCephe[
                        (photoIndex2 + kat3OnCephe.length - 1) %
                          kat3OnCephe.length
                      ]
                    }
                    onCloseRequest={() => setIsOpen2(false)}
                    onMovePrevRequest={() =>
                      setPhotoIndex2(
                        (photoIndex2 + kat3OnCephe.length - 1) %
                          kat3OnCephe.length
                      )
                    }
                    onMoveNextRequest={() =>
                      setPhotoIndex2((photoIndex2 + 1) % kat3OnCephe.length)
                    }
                  />
                )}
                <DaireButton
                  classWrapper="pro2"
                  oncephe={true}
                  katismi="2. Kat"
                  left={true}
                  onClick={() => {
                    setIsOpen3(true);
                  }}
                />
                {isOpen3 && (
                  <Lightbox
                    mainSrc={kat1OnCephe[photoIndex3]}
                    nextSrc={
                      kat1OnCephe[(photoIndex3 + 1) % kat1OnCephe.length]
                    }
                    prevSrc={
                      kat1OnCephe[
                        (photoIndex3 + kat1OnCephe.length - 1) %
                          kat1OnCephe.length
                      ]
                    }
                    onCloseRequest={() => setIsOpen3(false)}
                    onMovePrevRequest={() =>
                      setPhotoIndex3(
                        (photoIndex3 + kat1OnCephe.length - 1) %
                          kat1OnCephe.length
                      )
                    }
                    onMoveNextRequest={() =>
                      setPhotoIndex3((photoIndex3 + 1) % kat1OnCephe.length)
                    }
                  />
                )}
                <DaireButton
                  classWrapper="pro3"
                  oncephe={true}
                  katismi="1. Kat"
                  left={true}
                  onClick={() => {
                    setIsOpen4(true);
                  }}
                />
                {isOpen4 && (
                  <Lightbox
                    mainSrc={kat1OnCephe[photoIndex4]}
                    nextSrc={
                      kat1OnCephe[(photoIndex4 + 1) % kat1OnCephe.length]
                    }
                    prevSrc={
                      kat1OnCephe[
                        (photoIndex4 + kat1OnCephe.length - 1) %
                          kat1OnCephe.length
                      ]
                    }
                    onCloseRequest={() => setIsOpen4(false)}
                    onMovePrevRequest={() =>
                      setPhotoIndex4(
                        (photoIndex4 + kat1OnCephe.length - 1) %
                          kat1OnCephe.length
                      )
                    }
                    onMoveNextRequest={() =>
                      setPhotoIndex4((photoIndex4 + 1) % kat1OnCephe.length)
                    }
                  />
                )}
                <DaireButton
                  classWrapper="pro4"
                  oncephe={true}
                  katismi="Giriş Kat"
                  left={true}
                  onClick={() => {
                    setIsOpen5(true);
                  }}
                />
                {isOpen5 && (
                  <Lightbox
                    mainSrc={girisKat[photoIndex5]}
                    nextSrc={girisKat[(photoIndex5 + 1) % girisKat.length]}
                    prevSrc={
                      girisKat[
                        (photoIndex5 + girisKat.length - 1) % girisKat.length
                      ]
                    }
                    onCloseRequest={() => setIsOpen5(false)}
                    onMovePrevRequest={() =>
                      setPhotoIndex5(
                        (photoIndex5 + girisKat.length - 1) % girisKat.length
                      )
                    }
                    onMoveNextRequest={() =>
                      setPhotoIndex5((photoIndex5 + 1) % girisKat.length)
                    }
                  />
                )}
                <DaireButton
                  classWrapper="pro5"
                  arkacephe={true}
                  katismi="3. Kat Dublex"
                  onClick={() => {
                    setIsOpen6(true);
                  }}
                />
                {isOpen6 && (
                  <Lightbox
                    mainSrc={kat3ArkaCephe[photoIndex6]}
                    nextSrc={
                      kat3ArkaCephe[(photoIndex6 + 1) % kat3ArkaCephe.length]
                    }
                    prevSrc={
                      kat3ArkaCephe[
                        (photoIndex6 + kat3ArkaCephe.length - 1) %
                          kat3ArkaCephe.length
                      ]
                    }
                    onCloseRequest={() => setIsOpen6(false)}
                    onMovePrevRequest={() =>
                      setPhotoIndex6(
                        (photoIndex6 + kat3ArkaCephe.length - 1) %
                          kat3ArkaCephe.length
                      )
                    }
                    onMoveNextRequest={() =>
                      setPhotoIndex6((photoIndex6 + 1) % kat3ArkaCephe.length)
                    }
                  />
                )}
                <DaireButton
                  classWrapper="pro6"
                  arkacephe={true}
                  katismi="2. Kat"
                  onClick={() => {
                    setIsOpen7(true);
                  }}
                />
                {isOpen7 && (
                  <Lightbox
                    mainSrc={kat1ArkaCephe[photoIndex7]}
                    nextSrc={
                      kat1ArkaCephe[(photoIndex7 + 1) % kat1ArkaCephe.length]
                    }
                    prevSrc={
                      kat1ArkaCephe[
                        (photoIndex7 + kat1ArkaCephe.length - 1) %
                          kat1ArkaCephe.length
                      ]
                    }
                    onCloseRequest={() => setIsOpen7(false)}
                    onMovePrevRequest={() =>
                      setPhotoIndex7(
                        (photoIndex7 + kat1ArkaCephe.length - 1) %
                          kat1ArkaCephe.length
                      )
                    }
                    onMoveNextRequest={() =>
                      setPhotoIndex7((photoIndex7 + 1) % kat1ArkaCephe.length)
                    }
                  />
                )}
                <DaireButton
                  classWrapper="pro7"
                  arkacephe={true}
                  katismi="1. Kat"
                  onClick={() => {
                    setIsOpen8(true);
                  }}
                />{" "}
                {isOpen8 && (
                  <Lightbox
                    mainSrc={kat1ArkaCephe[photoIndex8]}
                    nextSrc={
                      kat1ArkaCephe[(photoIndex8 + 1) % kat1ArkaCephe.length]
                    }
                    prevSrc={
                      kat1ArkaCephe[
                        (photoIndex8 + kat1ArkaCephe.length - 1) %
                          kat1ArkaCephe.length
                      ]
                    }
                    onCloseRequest={() => setIsOpen8(false)}
                    onMovePrevRequest={() =>
                      setPhotoIndex8(
                        (photoIndex8 + kat1ArkaCephe.length - 1) %
                          kat1ArkaCephe.length
                      )
                    }
                    onMoveNextRequest={() =>
                      setPhotoIndex8((photoIndex8 + 1) % kat1ArkaCephe.length)
                    }
                  />
                )}
                <DaireButton
                  classWrapper="pro8"
                  arkacephe={true}
                  katismi="Bahçe Kat"
                  onClick={() => {
                    setIsOpen9(true);
                  }}
                />
                {isOpen9 && (
                  <Lightbox
                    mainSrc={bahceKat[photoIndex9]}
                    nextSrc={bahceKat[(photoIndex9 + 1) % bahceKat.length]}
                    prevSrc={
                      bahceKat[
                        (photoIndex9 + bahceKat.length - 1) % bahceKat.length
                      ]
                    }
                    onCloseRequest={() => setIsOpen9(false)}
                    onMovePrevRequest={() =>
                      setPhotoIndex9(
                        (photoIndex9 + bahceKat.length - 1) % bahceKat.length
                      )
                    }
                    onMoveNextRequest={() =>
                      setPhotoIndex9((photoIndex9 + 1) % bahceKat.length)
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </section>
        <section className="section-bg">
          <div className="container py-5">
            <div className="row">
              <div className="col-md-8 pb-3">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3008.107893144109!2d28.906027415415775!3d41.066634179294866!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab1fdb33e4619%3A0xbc1064de11c20d6b!2sArmedem%20in%C5%9Faat!5e0!3m2!1str!2str!4v1616750017977!5m2!1str!2str"
                  width="100%"
                  height="450"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </div>

              <div className="col-md-4 d-flex flex-column justify-content-center pb-3">
                <h1 className="text-white">SATIŞ OFİSİ</h1>
                <p className="text-white">
                  ADRES: <br /> Karadolap Mahallesi <br />
                  Gaziosmanpaia Caddesi <br /> No: 120 / E <br /> EYÜPSULTAN -
                  İSTANBUL{" "}
                </p>{" "}
                <p className="text-white">
                  TELEFON: <br />{" "}
                  <a
                    className="text-white text-decoration-none"
                    href="tel:+905322571583"
                  >
                    532 257 15 83
                  </a>{" "}
                  -{" "}
                  <a
                    className="text-white text-decoration-none"
                    href="+905392810050"
                  >
                    0539 281 00 50
                  </a>
                </p>
                <p className="text-white">
                  E-POSTA: <br />{" "}
                  <a
                    className="text-white text-decoration-none"
                    href="mailto:armedem37@gmail.com"
                  >
                    armedem37@gmail.com
                  </a>{" "}
                  -{" "}
                  <a
                    className="text-white text-decoration-none"
                    href="mailto:info@armedeminsaat.com.tr"
                  >
                    info@armedeminsaat.com.tr
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </Fragment>
  );
};

export default ProjectDetails;
