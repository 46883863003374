import React, { createRef, Fragment, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Style from "./header.module.scss";

const Header = ({ pushHeader, homeNav }) => {
  const [isActive, setIsActive] = useState(false);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [isSticky, setIsSticky] = useState(false);
  const ref = createRef();

  // mount
  useEffect(() => {
    const cachedRef = ref.current,
      observer = new IntersectionObserver(
        ([e]) => setIsSticky(e.intersectionRatio < 1),
        { threshold: [1] }
      );

    observer.observe(cachedRef);

    // unmount
    return function () {
      observer.unobserve(cachedRef);
    };
  }, []);

  return (
    <Fragment>
      <div id="homeGapper" className={pushHeader ? pushHeader : "d-none"}></div>
      <nav
        id="nav"
        className={`${isSticky ? "isSticky" : ""} navSticky`}
        ref={ref}
      >
        <div className="container">
          <div className="row d-flex justify-content-between">
            <div className="logo-wrapper">
              <a href={BASE_URL}>
                <img
                  className="w-100"
                  src={BASE_URL + "/images/logo.png"}
                  alt="logo"
                />
              </a>
            </div>
            <div className="d-none menu-wrapper d-md-flex align-items-center">
              <NavLink
                activeClassName={Style.active}
                className={Style.nav_item + " py-2 mx-4"}
                to="/"
                exact={true}
              >
                ANASAYFA
              </NavLink>
              <NavLink
                activeClassName={Style.active}
                className={Style.nav_item + " py-2 mx-4"}
                to="/hakkimizda"
              >
                HAKKIMIZDA
              </NavLink>
              <NavLink
                activeClassName={Style.active}
                className={Style.nav_item + " py-2 mx-4"}
                to="/projelerimiz"
              >
                PROJELERİMİZ
              </NavLink>
              <NavLink
                activeClassName={Style.active}
                className={Style.nav_item + " py-2 mx-4"}
                to="/iletisim"
              >
                İLETİŞİM
              </NavLink>
            </div>
            <div className={Style.mobileMenu + " d-block d-md-none"}>
              <a
                onClick={() => setIsActive(!isActive)}
                className={Style.hamburgerIcon}
                href="#"
              >
                <svg
                  height="384pt"
                  viewBox="0 -53 384 384"
                  width="384pt"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m368 154.667969h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0" />
                  <path d="m368 32h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0" />
                  <path d="m368 277.332031h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0" />
                </svg>
              </a>
              <div
                className={`${Style.openable} ${isActive ? Style.active : ""}`}
              >
                <ul>
                  <li>
                    <NavLink
                      activeClassName={Style.active}
                      className={Style.nav_item + " py-2 mx-4"}
                      to="/"
                      exact={true}
                    >
                      ANASAYFA
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName={Style.active}
                      className={Style.nav_item + " py-2 mx-4"}
                      to="/hakkimizda"
                    >
                      HAKKIMIZDA
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName={Style.active}
                      className={Style.nav_item + " py-2 mx-4"}
                      to="/projelerimiz"
                    >
                      PROJELERİMİZ
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName={Style.active}
                      className={Style.nav_item + " py-2 mx-4"}
                      to="/iletisim"
                    >
                      İLETİŞİM
                    </NavLink>
                  </li>
                </ul>
                <span onClick={() => setIsActive(!isActive)}>X</span>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </Fragment>
  );
};

export default Header;
