import React, { Fragment } from "react";
import Styles from "./footer.module.scss";

const Footer = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  return (
    <Fragment>
      <footer className="py-100">
        <div className={Styles.cont + " container"}>
          <div className="row">
            <div className="col-md-6">
              <p className={Styles.address}>
                Karadolap Mahallesi <br />
                Gaziosmanpaşa Caddesi <br />
                no: 120 / E <br />
                EYÜP / İSTANBUL
              </p>
            </div>
            <div className="col-md-6">
              <a
                className={Styles.sahibinden + " w-100 d-block pb-3"}
                href="https://www.sahibinden.com/ilan/emlak-konut-satilik-eyup-esentepe-mah-de-sahibinden-tapu-harcsiz-satilik-lux-daire-912039429/detay"
                target="_blank"
              >
                sahibinden.com/armedeminsaat
              </a>
              <a
                className={Styles.mail + " w-100 d-block pb-3"}
                href="mailto:armedem37@gmail.com"
              >
                armedem37@gmail.com
              </a>
              <a
                className={Styles.mail + " w-100 d-block pb-3"}
                href="mailto:info@armedeminsaat.com.tr"
              >
                info@armedeminsaat.com.tr
              </a>
              <a
                className={Styles.tel + " w-100 d-block pb-3"}
                href="tel:+905322571583"
              >
                0532 257 15 83
              </a>
              <a
                className={Styles.tel + " w-100 d-block pb-3"}
                href="tel:+905392510050"
              >
                0539 281 00 50
              </a>
            </div>
          </div>
        </div>
      </footer>
      <div className="container-fluid">
        <div className="row">
          <div className={Styles.absoluteFooter + " container"}>
            <div className="row h-100">
              <div className="col-md-6 text-center text-md-left h-100 d-md-flex align-items-center pt-2">
                <span>Tüm Hakları Saklıdır © 2021 Armedem İnşaat</span>
              </div>
              <div className="col-md-6 text-center text-md-right h-100 d-md-flex align-items-center justify-content-end">
                <img
                  src={BASE_URL + "/images/created-by-cloud.png"}
                  alt="created by cloud dijital group"
                />
                <img
                  src={BASE_URL + "/images/cloud-logo.png"}
                  alt="Cloud Dijital Group"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Footer;
