import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Layout from "../../layout/Layout.js";
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs.js";
import { useLocation } from "react-router-dom";

const AboutUs = () => {
  const { pathname } = useLocation();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Fragment>
      <MetaTags>
        <title>Armedem | Hakkımızda</title>
        <meta name="description" content="Ardenem | Anasayfa" />
      </MetaTags>
      <Layout>
        <div className="section-bg behind-nav"></div>
        <Breadcrumbs
          breadcrumbsitem="Hakkımızda"
          breadcrumbsitemLink="hakkimizda"
        />
        <section className="section-bg">
          <div className="container py-5">
            <div className="row">
              <div className="col-md-6">
                <h1 className="text-white">HAKKIMIZDA</h1>
                <p className="text-white">
                  Şirketimiz 2018 yılında inşaat işleri yapmak amacıyla Mehmet
                  DEMİR tarafından kurulmuştur. Bu doğrultuda ilk olarak Eyüp
                  Alibeyköy bölgesinde yer alan kendi ait olan arsasında 8
                  dairelik bir inşaat yaparak faaliyetlerine başlamıştır.
                </p>
                <h1 className="text-white">MİSYON</h1>
                <p className="text-white">
                  Şirket olarak kurulduğumuz günden beri misyonumuz yaptığımız
                  her işi yüksek kaliteden şaşmadan, olabilecek en düzgün ve
                  güvenilir bir şekilde yapmaktır.
                </p>
                <h1 className="text-white">VİZYON</h1>
                <p className="text-white">
                  Şirket olarak belirlediğimiz misyon çerçevesinde yaptığımız ve
                  ileride yapacağımız her yeni projede hem ülke ekonomisine bir
                  katkıda bulunmak hem de tamamladığımız ve tamamlayacağımız
                  projeler ile birlikte yaşanılabilir yüksek kaliteli yaşam
                  alanları inşa etmektir.
                </p>
              </div>
              <div className="col-md-6">
                <img
                  className="w-100"
                  src={BASE_URL + "/images/hakkimizda.jpg"}
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </Fragment>
  );
};

export default AboutUs;
