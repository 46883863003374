import React from "react";
import { Link } from "react-router-dom";
import Style from "./button.module.scss";
const Button = ({ linkTo, isWhite, tumProjeler, stillText }) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  return (
    <div>
      <Link className={Style.button} exact to={linkTo}>
        <div className={Style.buttonImg}>
          <img
            className={Style.icHalka}
            src={
              isWhite
                ? BASE_URL + "/images/beyaz-ic-halka.png"
                : BASE_URL + "/images/sari-ic-halka.png"
            }
            alt="buton iç halka"
          />
          <img
            className={Style.disHalka}
            src={
              isWhite
                ? BASE_URL + "/images/beyaz-dis-halka.png"
                : BASE_URL + "/images/sari-dis-halka.png"
            }
            alt="buton dış halka"
          />
        </div>
        <div className={Style.buttonTextWrapper}>
          <p
            className={`${Style.buttonText} ${stillText ? Style.still : ""} ${
              isWhite ? Style.whiteButton : Style.yellowButton
            }`}
          >
            {tumProjeler ? tumProjeler : "PROJE DETAYLARI"}
          </p>
        </div>
      </Link>
    </div>
  );
};

export const DaireButton = ({
  oncephe,
  arkacephe,
  katismi,
  classWrapper,
  left,
  onClick,
}) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  return (
    <div
      className={`${Style.parlama} ${left ? Style.left : Style.right} ${
        classWrapper ? classWrapper : ""
      }`}
      onClick={onClick}
    >
      <div className={arkacephe ? "d-none" : Style.oncephe}>
        {" "}
        <p className="m-0">{katismi}</p>{" "}
      </div>
      <img
        className={Style.katButtonResim}
        src={BASE_URL + "/images/sair-sirzat/sari-ic-arti.png"}
        alt="button"
      />
      <div className={oncephe ? "d-none" : Style.arkacephe}>
        {" "}
        <p className="m-0">{katismi}</p>{" "}
      </div>
    </div>
  );
};

export default Button;
