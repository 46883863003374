import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import Style from "./home.module.scss";
import Button from "../../components/button/Button.js";
import Flickity from "react-flickity-component";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import $ from "jquery";
import { useLocation } from "react-router-dom";

import Layout from "../../layout/Layout.js";

const Home = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const flickityOptions = {
    initialIndex: 0,
    contain: true,
    autoPlay: true,
    wrapAround: true,
  };
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [isCllicked, setIsClicked] = useState("1");

  useEffect(() => {
    const ucakAnimasyon = () => {
      if (isCllicked == 1) {
        $(".okul").removeClass("active");
        $(".avm").removeClass("active");
        $(".hastane").removeClass("active");
        setTimeout(() => {
          $(".ucak").addClass("active");
        }, 400);
      } else if (isCllicked == 2) {
        $(".ucak").removeClass("active");
        $(".avm").removeClass("active");
        $(".hastane").removeClass("active");
        setTimeout(() => {
          $(".okul").addClass("active");
        }, 400);
      } else if (isCllicked == 3) {
        $(".ucak").removeClass("active");
        $(".okul").removeClass("active");
        $(".hastane").removeClass("active");
        setTimeout(() => {
          $(".avm").addClass("active");
        }, 400);
      } else if (isCllicked == 4) {
        $(".ucak").removeClass("active");
        $(".okul").removeClass("active");
        $(".avm").removeClass("active");
        setTimeout(() => {
          $(".hastane").addClass("active");
        }, 400);
      }
    };
    ucakAnimasyon();
  }, isCllicked);

  return (
    <Fragment>
      {console.log(isCllicked)}
      <MetaTags>
        <title>Armedem | Anasayfa</title>
        <meta name="description" content="Ardenem | Anasayfa" />
      </MetaTags>
      <Layout pushHeader="d-block" homeNav={true}>
        <div className="container-fluid p-0 hero_banner_wrapper z__1">
          <div className="hero_banner">
            <div className="text_box w-100 position-fixed">
              <div className="container">
                <div className="row">
                  <div className="col-md-0 col-lg-6 offset-lg-6 offset-md-2 text-right">
                    <h1 className="slogan">“Yaşam Başladı.”</h1>
                    <img
                      className="mb-4"
                      src={BASE_URL + "/images/hero-button.png"}
                      alt="hero banner yardımcı resim"
                    />
                    <span className="slogan2 ml-3">Şair Şirzat Apt</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className={"bg-main " + Style.firstSection}>
          <div className="container py-100">
            <div className="row no-gutters hakkimizda-border">
              <div className="col-md-6 z-1">
                <img
                  className="w-100 hakkimizda-border"
                  src={BASE_URL + "/images/hakkimizda.jpg"}
                  alt="hakkımızda"
                />
              </div>
              <div className="col-md-6 ">
                <div className="hakkimizda-wrapper d-flex flex-column justify-content-center h-100">
                  <div>
                    <h1 className="text-white">HAKKIMIZDA</h1>
                    <p className="text-white">
                      Şirketimiz 2018 yılında inşaat işleri yapmak amacıyla
                      Mehmet DEMİR tarafından kurulmuştur. Bu doğrultuda ilk
                      olarak Eyüp Alibeyköy bölgesinde yer alan kendi ait olan
                      arsasında 8 dairelik bir inşaat yaparak faaliyetlerine
                      başlamıştır.
                    </p>
                    <Button linkTo="/hakkimizda" isWhite={true} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container home-iconlar">
            <div className="row">
              <div className="col-6 col-md-3 d-flex flex-column homeIconSect justify-content-between align-items-center">
                <img
                  className="w-50"
                  src={BASE_URL + "/images/quality.png"}
                  alt="kaliteli yapılar"
                />
                <h3 className="info-text text-center">KALİTELİ YAPILAR</h3>
              </div>
              <div className="col-6 col-md-3 d-flex flex-column homeIconSect justify-content-between align-items-center">
                <img
                  className="w-50"
                  src={BASE_URL + "/images/hand-shake.png"}
                  alt="güvenilirlik"
                />
                <h3 className="info-text text-center">GÜVENİLİRLİK</h3>
              </div>
              <div className="col-6 col-md-3 d-flex flex-column homeIconSect justify-content-between align-items-center">
                <img
                  className="w-50"
                  src={BASE_URL + "/images/modern-house.png"}
                  alt="modern mimari"
                />
                <h3 className="info-text text-center">MODERN MİMARİ</h3>
              </div>
              <div className="col-6 col-md-3 d-flex flex-column homeIconSect justify-content-between align-items-center">
                <img
                  className="w-50"
                  src={BASE_URL + "/images/home.png"}
                  alt="müşteri memnuniyeti"
                />
                <h3 className="info-text text-center">MÜŞTERİ MEMNUNİYETİ</h3>
              </div>
            </div>
          </div>
          <div className={Style.projelerimiz + " container pb-3"}>
            <div className="row">
              <div className="col-12">
                <h1>
                  PROJELERİMİZ <span></span>
                </h1>
              </div>
              <div className="col-12">
                <Flickity
                  className={"carousel homeProje " + Style.homeProje} // default ''
                  elementType={"div"} // default 'div'
                  options={flickityOptions} // takes flickity options {}
                  disableImagesLoaded={false} // default false
                  reloadOnUpdate // default false
                  static // default false
                >
                  <div className={Style.SliderContainer + "container"}>
                    <div className="row">
                      <div className={Style.projeImg + " col-md-6"}>
                        <img
                          className="w-100"
                          src={
                            BASE_URL +
                            "/images/sair-sirzat/sair-sirzat-kapak.jpg"
                          }
                          alt=""
                        />
                        <div className={Style.homeButtonProje}>
                          <Button
                            isWhite="white"
                            linkTo="/projelerimiz/sair-sirtaz-apt"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 d-flex flex-column justify-content-center homeProjeSlider">
                        <h2>Şair Şirzat Projesi</h2>
                        <p>
                          Projemiz toplam 8 daireden oluşmaktadır. Proje
                          kapsamında bahçe katı ve giriş katı tek daire şeklinde
                          tasarlanmış ve brüt 92’şer metrekaredir. 1. ve 2.
                          katlarda ise 2’şer daire bulunmaktadır. Bu daireler
                          ise brüt 70’şer metrekaredir. Projemizin 3. katında
                          yer alan dairelerimiz ise dubleks daire olarak
                          tasarlanmıştır.
                        </p>
                      </div>
                    </div>
                  </div>
                </Flickity>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center pb-5 sag-cizgi">
            <Button
              linkTo="/projelerimiz"
              tumProjeler="TÜM PROJELER"
              stillText={true}
            />
          </div>
        </section>
        <section className={"bg-main py-5 mt__2 " + Style.secondSection}>
          <div className={Style.projelerimiz + " container pb-5"}>
            <div className="row">
              <div className="col-12">
                <Flickity
                  className={"carousel carouselTehlike " + Style.homeProje} // default ''
                  elementType={"div"} // default 'div'
                  options={flickityOptions} // takes flickity options {}
                  disableImagesLoaded={false} // default false
                  reloadOnUpdate // default false
                  static // default false
                >
                  <div className={Style.SliderContainer + " container"}>
                    <div className="row">
                      <div
                        className={
                          Style.ucak + " col-md-5 col-lg-3 ucakWrapper"
                        }
                      >
                        <img
                          className="ucak active"
                          src={BASE_URL + "/images/ucak.png"}
                          alt=""
                        />
                        <img
                          className="okul"
                          src={BASE_URL + "/images/okul.png"}
                          alt=""
                        />
                        <img
                          className="avm"
                          src={BASE_URL + "/images/avm.png"}
                          alt=""
                        />
                        <img
                          className="hastane"
                          src={BASE_URL + "/images/hastane.png"}
                          alt=""
                        />
                      </div>
                      <div className="col-md-7 col-lg-5 d-flex flex-column justify-content-center tabsHome">
                        <h3 className="konum-ayricaliklari">
                          ŞAİR ŞİRZAT KONUM AYRICALIKLARI
                        </h3>
                        <Tabs>
                          <TabList>
                            <Tab
                              onClick={() => {
                                setIsClicked("1");
                              }}
                            >
                              ULAŞIM
                            </Tab>
                            <Tab
                              onClick={() => {
                                setIsClicked("2");
                              }}
                            >
                              EĞİTİM
                            </Tab>
                            <Tab
                              onClick={() => {
                                setIsClicked("3");
                              }}
                            >
                              ALIŞVERİŞ
                            </Tab>
                            <Tab
                              onClick={() => {
                                setIsClicked("4");
                              }}
                            >
                              SAĞLIK
                            </Tab>
                          </TabList>

                          <TabPanel>
                            <ul className="tabUl">
                              <li>-TEM: 3,5 km</li>
                              <li>
                                -Otobüs durağına ve minibüslere yürüme
                                mesafesinde
                              </li>
                              <li>-Alibeyköy metro istasyonuna 2 km</li>
                            </ul>
                          </TabPanel>
                          <TabPanel>
                            <ul className="tabUl">
                              <li>-Kılıçaslan İmam Hatip Ortaokuluna 650 m</li>
                              <li>-Prof. Kaya Gürsel Ortaokuluna 1,1 km</li>
                              <li>-Esentepe İlkokuluna 500 m</li>
                              <li>-Alibeyköy Ortaokuluna 400 m</li>
                            </ul>
                          </TabPanel>
                          <TabPanel>
                            <ul className="tabUl">
                              <li>
                                -İsfanbul (Vialand) Alışveriş Merkezine 2,5 km
                              </li>
                              <li>
                                -Biz Cevahir Haliç Alışveriş Merkezine 1,3 km
                              </li>
                            </ul>
                          </TabPanel>
                          <TabPanel>
                            <ul className="tabUl">
                              <li>
                                -Alibeyköy Ağız ve Diş Sağlığı Hastanesine 900 m
                              </li>
                              <li>-Eyüp Alibeyköy Semt Polikliniğine 900 m</li>
                              <li>-Alibey Hospital’e 2,4 km</li>
                            </ul>
                          </TabPanel>
                        </Tabs>
                      </div>

                      <div className={Style.ucakEv + " col-md-4"}>
                        <img
                          src={BASE_URL + "/images/sair-sirzat-ulasim-bina.png"}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </Flickity>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </Fragment>
  );
};

export default Home;
